import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PhotosCarouselMenu from "../photography/PhotosCarouselMenu"

const IndexPage = () => {
  return (
    <Layout wide>
      <SEO title="Udara's photography" />

      <div className="photography">
        <div className="header">
          <div className="des">A selection of my favourite photos</div>
        </div>
        <div className="photos">
          <PhotosCarouselMenu />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
