import React, { useState, useMemo } from "react"
import * as styles from "./PhotosCarouselMenu.module.scss"
import photographs from "../photographs.json"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PhotosCarouselMenu = ({ changeIndex = () => {} }) => {
  const [focused, setFocused] = useState(false)

  const toggleFocus = index => {
    setFocused(focused === index ? false : index)
  }

  const renderPhotos = useMemo(() => {
    return photographs.map((photo, i) => {
      const isLandscape = photo.width > photo.height

      return (
        <div
          key={i}
          className={`${styles.photo} ${focused === i ? styles.current : ""} ${
            isLandscape ? styles.landscape : ""
          }`}
        >
          <img
            src={`../photography/c/${photo.src}`}
            alt={`${photo.city}, ${photo.year}`}
            loading={i < 10 ? "eager" : "lazy"}
          />
          <div className={styles.meta}>
            <div>
              {photo.city}, {photo.year}
            </div>
            <div>{photo.exif.Model ?? "Fujifilm XT-30"}</div>
          </div>
        </div>
      )
    })
  }, [focused])

  return <div className={styles.container}>{renderPhotos}</div>
}

export default PhotosCarouselMenu
